.background {
  position: relative;
  background: var(--background);
  color: var(--text);
  z-index: 1;
}

.background:before {
  content: "";
  position: absolute;
  inset: 0;
  background-image: var(--image);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  opacity: 0.25;
  z-index: -1;
}
